import { PostCard } from '@/components/layout/NavigationBar/PostCard/PostCard';
import { blockUrlWithAudience } from '@/lib/utils';

import type { PostCardProps } from '@/components/layout/NavigationBar/PostCard/PostCard';
import type { CardItem } from '@/components/layout/NewNavigationBar/types';
import type { BlockWithAudience } from '@/types/shared';

export interface NavBlogPostProps extends CardItem, BlockWithAudience {}

export const NavBlogPost = ({
  manualCardItem,
  referencedCardItem,
  pageAudience,
  isAudienceSwitcherEnabled,
}: NavBlogPostProps) => {
  let postCard: PostCardProps;

  if (referencedCardItem) {
    const {
      post,
      description: showDescription,
      image: showImage,
      author: showAuthor,
    } = referencedCardItem;
    const {
      excerpt: description,
      featuredImage,
      slug,
      title,
      category,
      author,
    } = post;

    postCard = {
      title: title,
      blogUrl: `/blog/${slug.current}`,
      image: showImage && featuredImage,
      description: showDescription && description,
      category: category[0].name,
      categoryUrl: `/blog/category/${category[0].slug?.current}`,
      authorImage: showAuthor && author.image,
      authorUrl: showAuthor && author.slug?.current,
      authorName: showAuthor && author.name,
    };
  } else if (manualCardItem) {
    const {
      description,
      featuredImage,
      title,
      caption,
      noAudienceToggle,
      url,
    } = manualCardItem;

    postCard = {
      title: title,
      blogUrl: blockUrlWithAudience(
        url,
        pageAudience,
        pageAudience,
        noAudienceToggle,
        isAudienceSwitcherEnabled,
      ),
      image: featuredImage,
      description: description,
      category: caption,
    };
  }

  return (
    <PostCard
      {...postCard}
      pageAudience={pageAudience}
      isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
    />
  );
};
