import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PortableText as PortableTextComponent } from '@portabletext/react';

import { PageBlockVideo } from '@/components/blocks/PageBlockVideo';
import { Animate } from '@/components/common/Animate';
import BaseLink from '@/components/common/BaseLink/BaseLink';
import { HeadingLevelContext } from '@/components/common/Heading/context';
import {
  BlockQuote,
  Heading,
  List,
  Paragraph,
} from '@/components/common/MarkUp';
import { TooltipV2 } from '@/components/common/TooltipV2';
import { blockUrlWithAudience } from '@/lib/utils';

import type { TypographySizeTypes } from '@/styles/typography';
import type { CreateStyled } from '@emotion/styled';

export const visuallyHiddenStyle = () => css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

export const VisuallyHidden = styled.span`
  ${visuallyHiddenStyle}
`;

interface destinationProps {
  target: string;
  isExternalLink: boolean;
  newTabText: string;
}

export const determineDestination = (
  href: string,
  isExternal: boolean | null = null,
): destinationProps => {
  const isExternalLink =
    isExternal ||
    (href && href.includes('platform.multiverse.io')) ||
    (href &&
      !href.includes('multiverse.io') &&
      !href.startsWith('/') &&
      !href.startsWith('#'));
  return {
    target: isExternalLink ? '_blank' : '_self',
    isExternalLink,
    newTabText: isExternalLink ? '(opens new window)' : '',
  };
};

export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};

export const stringToId = (str: string): string => {
  return str.toLowerCase().replaceAll(' ', '-');
};

export const ConditionalAnimateWrapper = ({
  children,
  animate,
}): JSX.Element => {
  return animate?.enabled ? (
    <Animate {...animate}>{children}</Animate>
  ) : (
    children
  );
};

export const formatDate = (date: string) => {
  const formattedDate = new Date(`${date}T00:00`).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  return formattedDate;
};

const getHeading = (
  level,
  children,
  size = 'subheading-lg' as TypographySizeTypes,
) => (
  <HeadingLevelContext.Provider value={level}>
    <Heading size={size}>{children}</Heading>
  </HeadingLevelContext.Provider>
);

export const components = (audienceConfig?) => {
  return {
    block: {
      h1: ({ children }) => getHeading(1, children),
      h2: ({ children }) => getHeading(2, children),
      h3: ({ children }) => getHeading(3, children, 'subheading-md'),
      h4: ({ children }) => getHeading(4, children, 'subheading-sm'),
      h5: ({ children }) => getHeading(5, children, 'subheading-sm'),
      h6: ({ children }) => getHeading(6, children, 'subheading-sm'),
      blockquote: ({ children }) => (
        <BlockQuote>&quot;{children}&quot;</BlockQuote>
      ),
      normal: ({ children }) => (
        <Paragraph size="body-lg">{children}</Paragraph>
      ),
      heading: ({ children }) => (
        <Heading size="subheading-lg">{children}</Heading>
      ),
      youtube: ({ value }) => (
        <PageBlockVideo url={value.url} caption={value.caption} />
      ),
      'subheading-sm': ({ children }) => (
        <Paragraph size="subheading-sm">{children}</Paragraph>
      ),
      'body-small': ({ children }) => (
        <Paragraph size="body-lg">{children}</Paragraph>
      ),
      'headline-sm': ({ children }) => (
        <Heading size="heading-sm">{children}</Heading>
      ),
      'heading-sm': ({ children }) => (
        <Heading size="heading-sm">{children}</Heading>
      ),
    },
    marks: {
      highlight: ({ children }) => children,
      strong: ({ children }) => <b>{children}</b>,
      link: ({ value, children }) => {
        const { href, audienceRef, noAudienceToggle } = value;
        const { pageAudience, isAudienceSwitcherEnabled } =
          audienceConfig || {};

        const hrefWithAudience = blockUrlWithAudience(
          href,
          audienceRef,
          pageAudience,
          noAudienceToggle,
          isAudienceSwitcherEnabled,
        );

        const destination = determineDestination(hrefWithAudience);

        if (href) {
          return (
            <BaseLink
              href={hrefWithAudience}
              target={destination.target}
              rel={destination.isExternalLink ? 'external noopener' : null}
            >
              {children}
              {destination.isExternalLink && (
                <VisuallyHidden>{destination.newTabText}</VisuallyHidden>
              )}
            </BaseLink>
          );
        } else return <span>{children}</span>;
      },
      tooltip: ({ children, value }) => {
        if (!value.content) return <span>{children}</span>;

        return (
          <TooltipV2 key={value._key} mark={value}>
            {children}
          </TooltipV2>
        );
      },
    },
    list: ({ value, children }) => {
      if (value.listItem === 'number') {
        return (
          <List type="ol" size="subheading-sm">
            {children}
          </List>
        );
      } else {
        return <List size="subheading-sm">{children}</List>;
      }
    },
    listItem: ({ children }) => (
      <li>
        <span>{children}</span>
      </li>
    ),
    unknownMark: ({ children }) => children,
    unknownType: ({ children }) => children,
  };
};

export const PortableText = (props) => {
  return (
    <PortableTextComponent
      onMissingComponent={(message, options) => {
        // eslint-disable-next-line no-console
        console.error('PortableText: missing component', { message, options });
      }}
      components={{ ...components(props?.audienceConfig) }}
      {...props}
    />
  );
};
