import styled from '@emotion/styled';
import Link from 'next/link';

import { Image } from '@/components/common/image';
import { Paragraph } from '@/components/common/MarkUp';
import { Surface } from '@/components/common/Surface';
import { components, PortableText } from '@/components/common/utils';
import { Stack } from '@/components/layout/Stack';

import type { BlockWithAudience, TrackingTagType } from '@/types/shared';
import type {
  SanityBlock,
  SanityImageAsset,
  SanityKeyed,
  SanityReference,
} from 'sanity-codegen';

const portableTextComponents = (audienceConfig?) => {
  return {
    ...components(audienceConfig),
    block: ({ value, children }) => {
      switch (value.style) {
        case 'normal':
          return <Description size="body-md">{children}</Description>;
      }
    },
  };
};

export interface PostCardProps extends BlockWithAudience {
  category?: string;
  categoryUrl?: string;
  title: string;
  blogUrl: string;
  trackingTag?: TrackingTagType;
  image?: {
    _type: 'image';
    asset: SanityReference<SanityImageAsset>;
    alternateText?: string;
  };
  description?: string | Array<SanityKeyed<SanityBlock>>;
  authorImage?: {
    _type: 'image';
    asset: SanityReference<SanityImageAsset>;
    authorAlternateText?: string;
  };
  authorName?: string;
  authorUrl?: string;
  date?: string;
}

export const PostCard = ({
  category,
  categoryUrl,
  title,
  blogUrl,
  trackingTag,
  image,
  description,
  authorUrl,
  authorImage,
  authorName,
  date,
  pageAudience,
  isAudienceSwitcherEnabled,
}: PostCardProps) => {
  return (
    <Surface
      padding={false}
      surfaceColour="--surface-subtle"
      borderRadius="--radius-m"
      border="1px solid var(--border-warm-base)"
    >
      <CardStack spacing="--spacing-small">
        {image?.asset && (
          <ImageContainer>
            <Link href={blogUrl}>
              <Image {...image} objectFit="cover" alt="" />
            </Link>
          </ImageContainer>
        )}
        <ContentContainer>
          {category &&
            (categoryUrl ? (
              <Link href={categoryUrl}>
                <CategoryContainer size="label-md" enableHover={true}>
                  {category}
                </CategoryContainer>
              </Link>
            ) : (
              <CategoryContainer size="label-md" enableHover={false}>
                {category}
              </CategoryContainer>
            ))}
          <Link
            href={blogUrl}
            className={
              trackingTag?.blockName &&
              `tracking-${trackingTag.blockName}__${trackingTag.blockAction}`
            }
          >
            <Wrapper spacing="--space-component-lg">
              <TitleContainer size="label-lg">{title}</TitleContainer>
              {description && typeof description === 'string' ? (
                <Description size="body-md">{description}</Description>
              ) : (
                <PortableText
                  value={description}
                  components={portableTextComponents({
                    pageAudience,
                    isAudienceSwitcherEnabled,
                  })}
                />
              )}
            </Wrapper>
          </Link>
        </ContentContainer>
        {authorUrl && (
          <Link href={authorUrl}>
            <ByLineContainer>
              {authorImage?.asset && (
                <AuthorImageContainer>
                  <Image {...authorImage} sizes="50vw" alt={''} />
                </AuthorImageContainer>
              )}
              <div>
                {authorName && (
                  <AuthorCaption size="label-sm">By {authorName}</AuthorCaption>
                )}
                {date && <Date size="label-sm">{date}</Date>}
              </div>
            </ByLineContainer>
          </Link>
        )}
      </CardStack>
    </Surface>
  );
};

const CategoryContainer = styled(Paragraph)<{ enableHover?: boolean }>`
  color: var(--text-warm-subtle);
  text-transform: none;
  font-weight: var(--font-weight-regular);
  -webkit-line-clamp: 2;
  padding-bottom: var(--space-component-sm);
  ${({ enableHover }) =>
    enableHover &&
    `&:hover {
    text-decoration: underline;
  }`}
`;

const TitleContainer = styled(Paragraph)`
  color: var(--text-strong);
  -webkit-line-clamp: 3;
`;

// This wrapper allows to use "-webkit-line-clamp".
// Otherwise, padding would allow text to sneak through it.
const Wrapper = styled(Stack)`
  p {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  &:hover,
  &:focus-within {
    ${TitleContainer} {
      text-decoration: underline;
    }
  }
  &:focus-within {
    outline: 2px solid var(--border-action);
    outline-offset: 2px;
  }
`;

const ContentContainer = styled.div`
  padding: 0 var(--space-component-xl);

  &:first-child {
    padding-top: var(--space-component-xl);
  }

  &:last-child {
    padding-bottom: var(--space-component-xl);
  }

  &:only-child {
    padding-top: var(--space-component-xl);
  }
`;

const AuthorImageContainer = styled.div`
  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  a {
    display: block;
  }
`;

const AuthorCaption = styled(Paragraph)`
  margin-bottom: 2px;
  color: var(--text-warm-base);
`;

const Description = styled(Paragraph)`
  color: var(--text-warm-base);
  font-weight: var(--font-weight-regular);
  -webkit-line-clamp: 3;
`;

const Date = styled(Paragraph)`
  color: var(--text-warm-subtle);
  font-weight: var(--font-weight-regular);
`;

const ImageContainer = styled.div`
  img {
    height: 100%;
    padding: var(--spacing-xx-small);
    padding-bottom: 0;
    border: none;
    border-radius: var(--radius-m);
    object-fit: contain;
    aspect-ratio: 1.69/1;
  }
  &:hover + ${ContentContainer} ${TitleContainer} {
    text-decoration: underline;
  }
`;

const CardStack = styled(Stack)`
  a {
    text-decoration: none;
  }

  & + & {
    margin-top: var(--spacing-small);
  }
`;

const ByLineContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 var(--space-component-xl) var(--space-component-xl);
  gap: var(--spacing-x-small);

  &:hover,
  &:focus-within {
    ${AuthorCaption} {
      text-decoration: underline;
    }
  }
`;
