import styled from '@emotion/styled';
// eslint-disable-next-line import/no-unresolved
import getVideoId from 'get-video-id';
import dynamic from 'next/dynamic';

import { Section } from '@/components/layout/Section';

const Youtube = dynamic(() => import('react-youtube'), { ssr: false });

export interface PageBlockVideoProps {
  url: string;
  caption?: string;
  noMargin?: boolean;
  style?: string;
}

const PageBlockVideo = ({ url, caption }: PageBlockVideoProps) => {
  const { id = '' } = getVideoId(url ?? '');

  return (
    <Section className="page-block-video">
      <VideoContainer>
        <Youtube videoId={id} />
        <figcaption>{caption}</figcaption>
      </VideoContainer>
    </Section>
  );
};

const VideoContainer = styled.div`
  figcaption {
    text-align: center;
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 16px;
    padding: 0 10px;
    line-height: 20px;
  }
  div {
    margin-top: 16px;
    text-align: center;
  }
  iframe {
    max-width: 100%;
  }
`;
export { PageBlockVideo };
