import Head from 'next/head';

import { getSanityImage } from '@/lib/sanityUtils';

import type { SanitySEOType } from '@/types/sanity';

const defaultTitle = 'Multiverse | Changing the future of work';
const defaultDescription =
  "We're building an outstanding alternative to university and corporate training that opens up many pathways to a life of learning";

const Header = ({ SEO }: { SEO: SanitySEOType }) => (
  <Head>
    <title>{SEO?.title || defaultTitle}</title>
    <meta name="description" content={SEO?.description || defaultDescription} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta property="og:title" content={SEO?.title || defaultTitle} />
    <meta
      property="og:description"
      content={SEO?.description || defaultDescription}
    />
    {SEO?.openGraphImage?.asset && (
      <meta
        property="og:image"
        content={getSanityImage(SEO.openGraphImage)
          .width(1200)
          .fit('max')
          .url()}
      />
    )}
    <link rel="icon" type="image/svg+xml" sizes="any" href="/favicon.svg" />
    {SEO?.noindex && <meta name="robots" content="noindex" />}
  </Head>
);

export { Header };
