import { useEffect } from 'react';

import { Header } from '@/components/common/header';
import { INTERCOM_APP_ID } from '@/components/common/inline-scripts';
import { PreviewBanner } from '@/components/common/PreviewBanner';
import { SEOTags } from '@/components/layout/SharedLayout/SeoTags';
import { DYRecommendationContext, useActiveConsent } from '@/lib/dynamicYield';

import type { RecommendationContext } from '@/lib/dynamicYield';
import type { SanitySEOType } from '@/types/sanity';
import type { LocaleTranslations } from '@/types/shared';
import type { ReactNode } from 'react';

export interface SharedLayoutProps {
  localeTranslations?: LocaleTranslations;
  canonicalPagePath?: string;
  isIntercomEnabled?: boolean;
  recommendationContext?: RecommendationContext;
  SEO?: SanitySEOType;
  children?: ReactNode;
  preview?: boolean;
}

export const SharedLayout = ({
  children,
  localeTranslations,
  canonicalPagePath,
  isIntercomEnabled = false,
  recommendationContext,
  SEO,
  preview = false,
}: SharedLayoutProps) => {
  useEffect(() => {
    if (isIntercomEnabled && typeof window?.Intercom !== 'undefined') {
      window?.Intercom('boot', {
        app_id: `${INTERCOM_APP_ID}`,
        api_base: 'https://api-iam.eu.intercom.io',
      });
    }
  }, [isIntercomEnabled]);

  useActiveConsent();

  return (
    <>
      <DYRecommendationContext recommendationContext={recommendationContext} />

      <Header SEO={SEO} />
      <SEOTags
        localeTranslations={localeTranslations}
        canonicalPagePath={canonicalPagePath}
      />
      {children}
      {Boolean(preview) && <PreviewBanner />}
    </>
  );
};
