import { createElement } from 'react';
import styled from '@emotion/styled';

import { icons } from './Icons';

export type IconSet = keyof typeof icons;

export interface IconProps {
  icon: IconSet;
  size?: string;
  colour?: string;
}

/**
  Icon component used to render SVG icons.

  `currentColor` is used by default, meaning the colour of the icon will be inherited from the parent. Use `color` prop to override if needed.

  @field {string} `icon` renders the desired icon from the list provided

  @field {string} `size` the size at which the icon is rendered. Defaults to 16px

  @field {string} `colour` used to override the colour of the icon. Use colour token here, e.g. `--ultraviolet-900`

 */
export const Icon = ({ icon, size = '16px', colour, ...props }: IconProps) => {
  return (
    <IconWrapper size={size} colour={colour} {...props}>
      {createElement(icons[icon])}
    </IconWrapper>
  );
};

const IconWrapper = styled.div<{
  size: string;
  colour?: string;
}>`
  color: ${({ colour }) => (colour ? `var(${colour})` : 'currentColor')};
  width: ${({ size }) => `${size}`};
  height: ${({ size }) => `${size}`};
  display: flex;
`;
