import Head from 'next/head';
import { useRouter } from 'next/router';

import { getAlternateTags } from '@/components/layout/SharedLayout/getAlternateTags';
import { isValidLocale } from '@/lib/utils';

import type { LocaleTranslations } from '@/types/shared';

export const SEOTags = ({
  localeTranslations,
  canonicalPagePath,
}: {
  localeTranslations: LocaleTranslations;
  canonicalPagePath?: string;
}) => {
  const host = 'https://www.multiverse.io/';
  const router = useRouter();

  const hasAlternateTags =
    typeof localeTranslations === 'boolean' ||
    (Array.isArray(localeTranslations) && localeTranslations.length > 0);

  if (
    !router ||
    !isValidLocale(router.locale) ||
    (!hasAlternateTags && !canonicalPagePath)
  ) {
    return null;
  }

  const path = router.asPath;

  const canonicalHrefPathName = !router.pathname.includes('/blog')
    ? path
    : '/blog';

  const canonicalHref =
    host +
    router.locale +
    `${canonicalPagePath ? '/' + canonicalPagePath : canonicalHrefPathName}`;

  const { alternateTags, defaultHref } = getAlternateTags(
    localeTranslations,
    path,
  );

  return (
    <Head>
      {hasAlternateTags && (
        <>
          {alternateTags?.map((locale) => {
            return (
              <link
                key={locale.lang}
                rel="alternate"
                href={locale.href}
                hrefLang={locale.lang}
              />
            );
          })}
          <link rel="alternate" href={defaultHref} hrefLang="x-default" />
        </>
      )}
      <link rel="canonical" key="canonical" href={canonicalHref} />
    </Head>
  );
};
