import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Col } from '@/components/layout/Grid';
import { getTextColour } from '@/lib/colourUtils';

import type { SurfaceColourTokenType } from '@/types/colours';
import type { BorderRadius, Spacing } from '@/types/measures';
import type { Elevation } from '@/types/misc';
import type { ReactNode } from 'react';

interface SurfaceProps {
  children: ReactNode;
  className?: string;
  padding?: Spacing | false;
  border?: string;
  borderRadius?: BorderRadius;
  surfaceColour?: SurfaceColourTokenType;
  elevation?: Elevation;
}

export const Surface = styled.div<
  Pick<
    SurfaceProps,
    'padding' | 'surfaceColour' | 'borderRadius' | 'border' | 'elevation'
  >
>`
  flex: 1;

  ${({
    borderRadius = '',
    elevation,
    padding = '--spacing-large',
    surfaceColour = '--transparent',
    border = 'none',
  }) => css`
    padding: ${padding ? `var(${padding})` : 0};
    background-color: var(${surfaceColour});
    border: ${border};
    border-radius: ${borderRadius ? `var(${borderRadius})` : 0};
    color: var(${getTextColour(surfaceColour)});
    box-shadow: ${elevation ? `var(${elevation})` : 'none'};
  `}

  ${Col} > & {
    height: 100%;
  }
`;
