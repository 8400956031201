import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';

import type { componentSpacingOptions, Spacing } from '@/types/measures';

type ResponsiveSpacing = {
  base?: Spacing;
  sm?: Spacing;
  md?: Spacing;
  lg?: Spacing;
  xl?: Spacing;
};

export interface StackProps {
  spacing?: Spacing | ResponsiveSpacing | componentSpacingOptions;
}

const spacingDefaults = { base: '--spacing-medium' };

// Iterate over all breakpoints and return a css snippet if a value exists for the particular property
const getBreakpoints = (spacing: ResponsiveSpacing) => {
  const spacingWithDefault = { ...spacingDefaults, ...spacing };

  const styles = Object.entries(spacingWithDefault).map(
    ([breakpoint, value]) => {
      if (breakpoint === 'base') {
        return `
        gap: var(${value});
      `;
      }

      return `
      ${screen[breakpoint]} {
        gap: var(${value});
      }
    `;
    },
  );

  return styles.join(' ');
};

// As 'sizes' is a valid prop in HTML, it gets passed onto the Col div, even though it doesn't make sense here.
const shouldForwardProp = (propName: string) =>
  isPropValid(propName) && propName !== 'spacing';

export const Stack = styled('div', { shouldForwardProp })<StackProps>`
  width: 100%;

  display: flex;
  flex-direction: column;

  ${({ spacing = '--spacing-medium' }) => {
    if (typeof spacing === 'string')
      return css`
        gap: var(${spacing});
      `;

    return getBreakpoints(spacing);
  }}
`;
