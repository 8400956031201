import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getImageProps } from 'next/image';

import { screen } from '@/components/common/breakpoints';
import { getSanityImageUrl } from '@/lib/sanityUtils';

import type {
  BackgroundColourTokenType,
  TextColourTokenType,
} from '@/types/colours';
import type { ReactNode } from 'react';
import type { SanityImageCrop, SanityImageHotspot } from 'sanity-codegen';

type verticalPaddingProps =
  | boolean
  | 'hero'
  | 'hero-tight'
  | 'leading'
  | 'default'
  | 'reduced-top'
  | 'reduced-bottom'
  | 'reduced-top-bottom';
interface SectionProps {
  className: string;
  backgroundColour?: BackgroundColourTokenType;
  backgroundImage?: {
    _type: 'image';
    crop?: SanityImageCrop;
    hotspot?: SanityImageHotspot;
  };
  backgroundImageMobile?: {
    _type: 'image';
    crop?: SanityImageCrop;
    hotspot?: SanityImageHotspot;
  };
  backgroundPosition?: string;
  backgroundPositionMobile?: string;
  colour?: TextColourTokenType;
  children?: ReactNode;
  breakout?: boolean;
  verticalPadding?: verticalPaddingProps;
  priority?: boolean;
  newNavigation?: boolean;
}

export const Section = ({
  children,
  backgroundColour = '--background-subtle',
  backgroundImage,
  backgroundImageMobile,
  backgroundPosition,
  backgroundPositionMobile,
  colour,
  verticalPadding = false,
  breakout = false,
  className,
  newNavigation,
  priority = false,
}: SectionProps) => {
  const image = backgroundImage && getSanityImageUrl(backgroundImage);
  const mobileImage =
    backgroundImageMobile && getSanityImageUrl(backgroundImageMobile);

  const innerComponent = breakout ? (
    children
  ) : (
    <WidthConstraint>{children}</WidthConstraint>
  );

  const common = { alt: '', sizes: '100vw' };

  const {
    props: { srcSet: desktop },
  } = getImageProps({
    ...common,
    quality: 80,
    fill: true,
    src: image,
    priority: priority,
  });
  const {
    props: { srcSet: mobile, ...rest },
  } = getImageProps({
    ...common,
    quality: 70,
    fill: true,
    src: mobileImage ?? image,
    priority: priority,
  });

  return (
    <SectionElem
      verticalPadding={verticalPadding}
      backgroundColour={backgroundColour}
      colour={colour}
      className={className}
      newNavigation={newNavigation}
    >
      {image && (
        <picture>
          {mobileImage && (
            <>
              <source media="(max-width: 960px)" srcSet={mobile} />
              <source media="(min-width: 961px)" srcSet={desktop} />
            </>
          )}
          {!mobileImage && <source srcSet={desktop} />}
          <SectionImage
            {...rest}
            alt=""
            backgroundPosition={backgroundPosition ?? 'center'}
            backgroundPositionMobile={backgroundPositionMobile ?? 'center'}
          />
        </picture>
      )}
      {innerComponent}
    </SectionElem>
  );
};

const getVerticalPadding = (
  verticalPadding: SectionProps['verticalPadding'],
  newNavigation?: boolean,
) => {
  switch (verticalPadding) {
    case 'hero':
      return css`
        --first-child-padding: var(--spacing-x-large);
        padding-top: ${newNavigation
          ? `var(--navbar_ex-padding-top)`
          : `calc(var(--full-navbar-height) + var(--spacing-x-large))`};
      `;

    case 'hero-tight':
      return css`
        --first-child-padding: 0px;
        padding-top: calc(var(--full-navbar-height) + var(--spacing-x-large));
      `;

    case 'leading':
      return css`
        padding-top: var(--spacing-400);

        ${screen.md} {
          padding-top: var(--spacing-600);
        }

        ${screen.lg} {
          padding-top: var(--spacing-800);
        }
      `;

    case 'reduced-top':
      return css`
        padding: var(--spacing-100) 0 var(--spacing-400);

        ${screen.md} {
          padding-bottom: var(--spacing-600);
        }

        ${screen.lg} {
          padding-bottom: var(--spacing-800);
        }
      `;

    case 'reduced-bottom':
      return css`
        padding: var(--spacing-400) 0 var(--spacing-100);

        ${screen.md} {
          padding-top: var(--spacing-600);
        }

        ${screen.lg} {
          padding-top: var(--spacing-800);
        }
      `;

    case 'reduced-top-bottom':
      return css`
        padding: var(--spacing-100) 0;
      `;

    case true:
    case 'default':
      return css`
        padding: var(--spacing-400) 0;

        ${screen.md} {
          padding: var(--spacing-600) 0;
        }

        ${screen.lg} {
          padding: var(--spacing-800) 0;
        }
      `;

    case false:
    default:
      break;
  }
};

type SectionImageProps = Pick<
  SectionProps,
  'backgroundPosition' | 'backgroundPositionMobile'
>;

type SectionElemProps = Pick<
  SectionProps,
  'verticalPadding' | 'backgroundColour' | 'colour'
> & {
  newNavigation?: boolean;
};

const SectionImage = styled.img<SectionImageProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 0;
  top: 0;
  object-position: center;

  ${(props) =>
    props.backgroundPosition &&
    css`
      object-position: ${props.backgroundPosition};
    `}

  @media (max-width: 960px) {
    ${(props) =>
      props.backgroundPositionMobile &&
      css`
        object-position: ${props.backgroundPositionMobile};
      `}
  }
`;

export const SectionElem = styled.section<SectionElemProps>`
  position: relative;
  overflow: clip;

  ${(props) => css`
    background-color: var(${props.backgroundColour});
    color: var(${props.colour});
  `}

  ${({ verticalPadding, newNavigation }) =>
    getVerticalPadding(verticalPadding, newNavigation)}
`;

type Sizings = 'base' | 'sm' | 'md' | 'lg';
interface WidthConstraintProps {
  includePadding?: Sizings[];
}

const baseList = ['base', 'sm', 'md', 'lg'];
const fallback = '@media(min-width: 0)';

/**
 * Used to constrain the width of the content of Section
 * If using the breakout option of section, this can be used to add constraints back in
 *
 * @param {Array} includePadding A list of breakpoints where the external padding should be visible. Remove values to create a "full bleed" effect
 */
export const WidthConstraint = styled.div<WidthConstraintProps>`
  margin: 0 auto;
  max-width: var(--desktop-max-width);
  position: relative;

  ${({ includePadding = baseList }) => {
    // Use reduce to merge media queries together into a string.
    return baseList.reduce((acc, current) => {
      if (includePadding.includes(current)) {
        return `${acc} ${
          screen[current] ?? fallback
        } { padding: 0 var(--section-gutter) };`;
      }
      return `${acc} ${screen[current] ?? fallback} { padding: 0  };`;
    }, '');
  }}
`;
