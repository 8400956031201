import styled from '@emotion/styled';
import { useNextSanityImage } from 'next-sanity-image';
import NextImage from 'next/image';

import { getClient } from '@/lib/sanityUtils';

import type { ImageProps as NextImageProps } from 'next/image';
import type { CSSProperties } from 'react';

const client = getClient();

export interface ImageProps
  extends Omit<NextImageProps, 'src' | 'alt' | 'objectFit' | 'objectPosition'> {
  _type?: string;
  src?: NextImageProps['src'];
  asset?: unknown;
  objectFit?: CSSProperties['objectFit'];
  objectPosition?: CSSProperties['objectPosition'];
  alt?: string;
  alternateText?: string;
  isDecorativeImage?: boolean;
  preserveMaxDimensions?: boolean;
  radius?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'full';
  link?: string;
}

export const Image = ({
  asset,
  sizes = '(max-width: 800px) 100vw, 800px',
  objectFit,
  objectPosition,
  src,
  style,
  alt,
  alternateText,
  isDecorativeImage = false,
  preserveMaxDimensions = false,
  radius,
  role,
  ...props
}: ImageProps) => {
  const sanityImage = useNextSanityImage(client, asset, {});

  const altText = alt ?? alternateText ?? '';
  const maxHeight = preserveMaxDimensions ? sanityImage?.height : null;
  const maxWidth = preserveMaxDimensions ? sanityImage?.width : null;
  const borderRadius = radius && `var(--radius-${radius})`;

  if (src) {
    return (
      <StyledNextImage
        src={src}
        sizes={sizes}
        fill={objectFit === 'fill'}
        style={{
          objectFit,
          objectPosition,
          maxHeight,
          maxWidth,
          borderRadius,
          ...style,
        }}
        {...props}
        alt={isDecorativeImage ? '' : altText}
        role={isDecorativeImage ? 'presentation' : role}
      />
    );
  }

  return (
    <StyledNextImage
      {...sanityImage}
      sizes={sizes}
      fill={objectFit === 'fill'}
      style={{
        objectFit,
        objectPosition,
        maxHeight,
        maxWidth,
        borderRadius,
        ...style,
      }}
      {...props}
      alt={isDecorativeImage ? '' : altText}
      role={isDecorativeImage ? 'presentation' : role}
    />
  );
};

const StyledNextImage = styled(NextImage)`
  width: 100%;
  height: auto;
`;
