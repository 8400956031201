import dynamic from 'next/dynamic';

const Add = dynamic(() => import(`@/assets/icons/Add.svg`));
const AlertCircle = dynamic(() => import(`@/assets/icons/AlertCircle.svg`));
const ArrowUp = dynamic(() => import(`@/assets/icons/arrow-up.svg`));
const ArrowDown = dynamic(() => import(`@/assets/icons/arrow-down.svg`));
const ArrowLeft = dynamic(() => import(`@/assets/icons/arrow-left.svg`));
const ArrowRight = dynamic(() => import(`@/assets/icons/arrow-right.svg`));
const ArrowCircleRight = dynamic(
  () => import(`@/assets/icons/ArrowCircleRight.svg`),
);
const Calendar = dynamic(() => import(`@/assets/icons/Calendar.svg`));
const Check = dynamic(() => import(`@/assets/icons/Check.svg`));
const CheckCircle = dynamic(() => import(`@/assets/icons/CheckCircle.svg`));
const ChevronDown = dynamic(() => import(`@/assets/icons/chevron-down.svg`));
const ChevronRight = dynamic(() => import(`@/assets/icons/chevron-right.svg`));
const ChevronLeft = dynamic(() => import(`@/assets/icons/chevron-left.svg`));
const ChevronUp = dynamic(() => import(`@/assets/icons/chevron-up.svg`));
const Close = dynamic(() => import(`@/assets/icons/close.svg`));
const Email = dynamic(() => import(`@/assets/icons/Email.svg`));
const Hexagon = dynamic(() => import(`@/assets/icons/Hexagon.svg`));
const House = dynamic(() => import(`@/assets/icons/House.svg`));
const InfoCircle = dynamic(() => import(`@/assets/icons/InfoCircle.svg`));
const LinkedIn = dynamic(() => import(`@/assets/icons/LinkedIn.svg`));
const LocationPin = dynamic(() => import(`@/assets/icons/LocationPin.svg`));
const NavigationMenu = dynamic(() => import(`@/assets/icons/menu.svg`));
const Play = dynamic(() => import(`@/assets/icons/Play.svg`));
const PlayCircle = dynamic(() => import(`@/assets/icons/PlayCircle.svg`));
const Search = dynamic(() => import(`@/assets/icons/Search.svg`));

export const icons = {
  Add,
  AlertCircle,
  ArrowCircleRight,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowDown,
  Calendar,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronRight,
  ChevronLeft,
  ChevronUp,
  Close,
  Email,
  Hexagon,
  House,
  InfoCircle,
  LinkedIn,
  LocationPin,
  NavigationMenu,
  Play,
  PlayCircle,
  Search,
};
